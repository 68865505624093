export default {
  proxy_path: "https://5r17kwb911.execute-api.ap-southeast-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://689lonz1h3.execute-api.ap-southeast-2.amazonaws.com/api",
  google_api_key: "AIzaSyCYOofv7ePg35vhHG6jVcyRqHmVvUPXRLU",
  main_company_term_id: "2277",
  crm_base_url: "https://crm.testing.newcrest.forwoodsafety.com",
  forwood_id_url: "https://id.testing.newcrest.forwoodsafety.com?redirect_uri=https://mapreport.testing.newcrest.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.testing.newcrest.forwoodsafety.com",
  Auth: {
    userPoolId: "ap-southeast-2_vpNG1OXId",
    userPoolWebClientId: "1t8b2lefk50jm351fg95r4unhb",
    cookieStorage: {
      domain: ".testing.newcrest.forwoodsafety.com",
      secure: true
    }
  }
};
